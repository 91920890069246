<template>
  <div class="container mx-auto p-6 max-w-4xl">
    <h1 class="text-4xl font-bold text-center text-gray-800 mb-10">TO DO LIST</h1>

    <!-- Add Task Button -->
    <button @click="openModal" class="mb-6 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
      Add New Task
    </button>

    <!-- Task Listing Section -->
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <!-- Active Tasks -->
      <div>
        <h2 class="text-xl font-semibold text-gray-800 mb-4">Active Tasks</h2>
        <ul class="space-y-4">
          <li v-for="task in activeTasks" :key="task._id"
            :class="['bg-white p-4 rounded-lg shadow-md', isNearDeadline(task) ? 'border-l-4 border-yellow-500' : '']">
            <div class="flex items-start justify-between">
              <div class="flex-1">
                <h3 class="text-lg font-medium text-gray-900 break-words">
                  {{ task.title }}
                </h3>
                <p class="text-sm text-gray-600 mt-1">{{ task.description }}</p>
                <p class="text-xs text-gray-500 mt-2">Deadline: <span :class="{ 'text-red-500': isOverdue(task) }">{{
                  formatDate(task.deadline) }}</span></p>
                <p class="text-xs text-gray-500">Group: {{ task.projectGroup }}</p>
                <p class="text-xs text-gray-500">Countdown: {{ getCountdown(task) }}</p>
              </div>
              <div class="flex items-center gap-3">
                <button @click="toggleTask(task._id)" class="text-green-500 hover:text-green-700">
                  <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                </button>
                <button @click="confirmDelete(task._id)" class="text-red-500 hover:text-red-700">
                  <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- Completed Tasks -->
      <div>
        <h2 class="text-xl font-semibold text-gray-800 mb-4">Completed Tasks</h2>
        <ul class="space-y-4">
          <li v-for="task in completedTasks" :key="task._id" class="bg-gray-50 p-4 rounded-lg shadow-md">
            <div class="flex items-start justify-between">
              <div>
                <h3 class="text-lg font-medium text-gray-500 line-through">{{ task.title }}</h3>
                <p class="text-sm text-gray-400 mt-1">{{ task.description }}</p>
                <p class="text-xs text-gray-500">Completed: {{ formatDate(task.completedAt) }}</p>
                <p class="text-xs text-gray-500">Group: {{ task.projectGroup }}</p>
              </div>
              <div class="flex items-center gap-3">
                <button @click="undoTask(task._id)" class="text-blue-500 hover:text-blue-700">
                  <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                  </svg>
                </button>
                <button @click="confirmDelete(task._id)" class="text-red-500 hover:text-red-700">
                  <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Add Task Modal -->
    <div v-if="showModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
      aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form @submit.prevent="addTask" class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="mb-4">
              <label for="task-title" class="block text-sm font-medium text-gray-700">Task Title</label>
              <input id="task-title" v-model="newTask.title" type="text"
                class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="What do you need to do?" required />
            </div>

            <div class="mb-4">
              <label for="task-description" class="block text-sm font-medium text-gray-700">Task Description</label>
              <textarea id="task-description" v-model="newTask.description" rows="3"
                class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                placeholder="Describe the task..." required></textarea>
            </div>

            <div class="mb-4">
              <label for="project-group" class="block text-sm font-medium text-gray-700">Group</label>
              <div class="mt-1 relative">
                <select id="project-group" v-model="newTask.projectGroup"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  <option disabled value="">Select a group</option>
                  <option v-for="group in projectGroups" :key="group" :value="group">
                    {{ group }}
                  </option>
                </select>
                <button v-if="newTask.projectGroup" type="button" @click="deleteProjectGroup(newTask.projectGroup)"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center text-red-500 hover:text-red-700">
                  <svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>

            <div class="mb-4">
              <label for="custom-project-group" class="block text-sm font-medium text-gray-700">Add Group</label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input type="text" v-model="customProjectGroup"
                  class="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                  placeholder="New group">
                <button type="button" @click="addCustomProjectGroup"
                  class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  Add
                </button>
              </div>
            </div>

            <div class="mb-4">
              <label for="task-deadline" class="block text-sm font-medium text-gray-700">Set Deadline</label>
              <input id="task-deadline" v-model="newTask.deadline" type="datetime-local"
                class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="submit"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm">
                Add Task
              </button>
              <button type="button" @click="closeModal"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
      aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Confirm Deletion
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Are you sure you want to delete this task? This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" @click="deleteTask"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
            <button type="button" @click="cancelDelete"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      tasks: [],
      newTask: {
        title: '',
        description: '',
        projectGroup: '',
        deadline: ''
      },
      showModal: false,
      projectGroups: [],
      customProjectGroup: '',
      intervalId: null,
      showDeleteModal: false,
      taskToDelete: null
    }
  },
  computed: {
    activeTasks() {
      return this.tasks.filter(t => !t.completed);
    },
    completedTasks() {
      return this.tasks.filter(t => t.completed);
    }
  },
  methods: {
    async fetchTasks() {
      try {
        const response = await axios.get('http://localhost:3000/api/tasks');
        this.tasks = response.data;
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },
    getCountdown(task) {
      if (!task.deadline || task.completed) return 'N/A';

      const deadlineDate = new Date(task.deadline);
      const now = new Date();
      const timeDiff = deadlineDate - now;

      if (timeDiff <= 0) {
        return 'Overdue';
      }

      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      return `${hours}h ${minutes}m ${seconds}s`;
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        this.tasks = [...this.tasks]; // Force Vue to re-render the countdown
      }, 1000); // Update every second
    },
    stopCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    async fetchProjectGroups() {
      try {
        const response = await axios.get('http://localhost:3000/api/project-groups');
        this.projectGroups = response.data;
      } catch (error) {
        console.error('Error fetching Groups:', error);
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.resetNewTask();
    },
    resetNewTask() {
      this.newTask = {
        title: '',
        description: '',
        projectGroup: '',
        deadline: ''
      };
    },
    async addTask() {
      try {
        await axios.post('http://localhost:3000/api/tasks', this.newTask);
        await this.fetchTasks();
        this.closeModal();
      } catch (error) {
        console.error('Error adding task:', error);
      }
    },
    async toggleTask(id) {
      try {
        await axios.put(`http://localhost:3000/api/tasks/${id}/toggle`);
        await this.fetchTasks();
      } catch (error) {
        console.error('Error toggling task:', error);
      }
    },
    confirmDelete(id) {
      this.showDeleteModal = true;
      this.taskToDelete = id;
    },
    cancelDelete() {
      this.showDeleteModal = false;
      this.taskToDelete = null;
    },
    async deleteTask() {
      if (!this.taskToDelete) return;
      try {
        await axios.delete(`http://localhost:3000/api/tasks/${this.taskToDelete}`);
        await this.fetchTasks();
        this.showDeleteModal = false;
        this.taskToDelete = null;
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    },
    async undoTask(id) {
      try {
        await axios.put(`http://localhost:3000/api/tasks/${id}/undo`);
        await this.fetchTasks();
      } catch (error) {
        console.error('Error undoing task:', error);
      }
    },
    async deleteProjectGroup(groupName) {
      if (confirm(`Are you sure you want to delete the Group "${groupName}"? All tasks in this group will be moved to "Uncategorized".`)) {
        try {
          await axios.delete(`http://localhost:3000/api/project-groups/${groupName}`);
          await this.fetchProjectGroups();
          await this.fetchTasks(); // Refresh tasks to reflect the changes
          this.newTask.projectGroup = ''; // Reset the selected Group in the form
        } catch (error) {
          console.error('Error deleting Group:', error);
        }
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'Not set';
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    isOverdue(task) {
      if (!task.deadline || task.completed) return false;
      return new Date(task.deadline) < new Date();
    },
    isNearDeadline(task) {
      if (!task.deadline || task.completed) return false;
      const deadlineDate = new Date(task.deadline);
      const now = new Date();
      const timeDiff = deadlineDate - now;
      const hoursDiff = timeDiff / (1000 * 60 * 60);
      return hoursDiff > 0 && hoursDiff <= 24; // Highlight if within the next 24 hours
    },
    async addCustomProjectGroup() {
      if (this.customProjectGroup && !this.projectGroups.includes(this.customProjectGroup)) {
        try {
          await axios.post('http://localhost:3000/api/project-groups', { name: this.customProjectGroup });
          await this.fetchProjectGroups();
          this.newTask.projectGroup = this.customProjectGroup; // Set the new group as the selected one
          this.customProjectGroup = ''; // Clear the input after adding
        } catch (error) {
          console.error('Error creating Group:', error);
        }
      }
    }
  },
  mounted() {
    this.fetchTasks();
    this.fetchProjectGroups();
    this.startCountdown();
  },
  beforeUnmount() {
    this.stopCountdown();
  }
}
</script>